$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

@import "./partials/header";
@import "./partials/home";
@import "./partials/about";
@import "./partials/services";
@import "./partials/facts";
@import "./partials/blog";
@import "./partials/footer";

@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
// @import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import "react-alice-carousel/lib/scss/alice-carousel.scss";

// Custom
body {
  height: 100vh;
  font-family: "Roboto", sans-serif;
}
.section-header {
  .section-title {
    font-weight: bold;
    color: #4e4e4e;
    font-family: "Raleway", sans-serif;
    position: relative;
    margin-bottom: 2rem;
    span {
      color: #248eff;
    }
    &:after {
      content: "";
      content: "";
      display: block;
      height: 3px;
      width: 70px;
      background: #248eff;
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .section-subtitle {
    max-width: 700px;
    color: #6f6f6f;
    font-size: 1.2rem;
  }
}

// Images
.clients-logo {
  width: 200px;
}

.partners-logo {
  height: 80px;
}

// Team section
.member-profile {
  top: -100px;
  left: 0;
}

.member-profile img {
  width: 150px;
  height: 150px;
}

.rounded-circle {
  border-radius: 50% !important;
}

// override bootstrap colours
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

$primary: #275b7a;
$primary-soft: #40708d;
$primary-opacity: rgba(39, 91, 122, 0.25);

$secondary-1: #268756;
$secondary-1-soft: #419c6e;

$secondary-2: #333985;
$complement: #c08236;

$primary-dark: #05304a;
$secondary-1-dark: #015128;

.text-primary {
  color: $primary;
}

.text-secondary-1 {
  color: $secondary-1;
}

.text-secondary-2 {
  color: $secondary-2;
}

.text-complement {
  color: $complement;
}

.secondary .header .nav-link.active {
  color: $primary-soft !important;
}

#home .home-content:before,
#facts .facts-container:before {
  background-color: $primary-dark;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;

  &:hover {
    background-color: $primary-soft;
    border-color: $primary-soft;
  }
}

.btn-secondary-1 {
  background-color: $secondary-1;
  border-color: $secondary-1;
  color: #fff !important;

  &:hover {
    background-color: $secondary-1-soft;
    border-color: $secondary-1-soft;
  }
}

.section-header .section-title span {
  color: $primary-soft;
}

.section-header .section-title::after {
  background: $primary-soft;
}

.text-primary,
.nav-link {
  color: $primary !important;
}

.icon-primary {
  color: $primary;
}

.icon-secondary-1 {
  color: $secondary-1;
}

.icon-secondary-2 {
  color: $secondary-2;
}

.icon-complement {
  color: $complement;
}

footer {
  .footer-title:after {
    background: $secondary-1;
  }

  a {
    color: $secondary-1;
  }
}

.partner-logo {
  width: 200px;
  margin: 0 0.1rem;
}

.partners-heading {
  margin: auto;
}

.nav-pills .nav-link.active {
  background-color: $primary;
  color: #fff !important;
}

.nav-pills .nav-link:not(.active):hover {
  background-color: $primary-opacity;
}
